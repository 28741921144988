<template>
    <div class="card-wrap">
        <div class="card-content">
            <production-card v-for="(item, idx) in cards" :key="idx" :title="item.Name" :desc="item.ProductDescription"
                :imgSrc="item.ImagePath" :id="item.Id"></production-card>
        </div>
    </div>
</template>

<script>
//import ProductionCard from "../components/production/ProductionCard.vue";
import ProductionCard from "@/components/production/ProductionCard.vue";
export default {
    components: { ProductionCard },
    data() {
        return {
            list: [],
            items: [
                {
                    Name: "大剧院系列",
                    ProductDescription:
                        "澳大利亚The Wharf的剧院",
                    ImagePath: "../assets/DJY/adly1.jpg",
                    Id: "adly",
                },
                {
                    Name: "大剧院系列",
                    ProductDescription: "南通大剧院与美术馆",
                    ImagePath: "../assets/DJY/ntdjy1.jpg",
                    Id: "ntdjy",
                },
                {
                    Name: "大剧院系列",
                    ProductDescription:
                        "苏州湾大剧院",
                    ImagePath: "../assets/DJY/szwdjy1.jpg",
                    Id: "szwdjy",
                },
                {
                    Name: "大剧院系列",
                    ProductDescription:
                        "扬州大运河大剧院",
                    ImagePath: "../assets/DJY/yzdyh1.jpg",
                    Id: "yzdyh",
                },
                {
                    Name: "大剧院系列",
                    ProductDescription:
                        "国家大剧院西餐厅",
                    ImagePath: "../assets/DJY/gjdjy1.jpg",
                    Id: "gjdjy",
                },
                {
                    Name: "大剧院系列",
                    ProductDescription:
                        "宛平剧场改扩建工程",
                    ImagePath: "../assets/DJY/wpjc1.jpg",
                    Id: "wpjc",
                },
                {
                    Name: "大剧院系列",
                    ProductDescription:
                        "无锡大剧院",
                    ImagePath: "../assets/DJY/wxdjy2.jpg",
                    Id: "wxdjy",
                },

                {
                    Name: "学校系列",
                    ProductDescription:
                        "奉贤区老年大学",
                    ImagePath: "../assets/XX/shlndx1.jpg",
                    Id: "shlndx",
                },
                {
                    Name: "学校系列",
                    ProductDescription:
                        "复旦江湾校区新建综合体育馆",
                    ImagePath: "../assets/XX/fdjwxq1.jpg",
                    Id: "fdjwxq",
                },
                {
                    Name: "学校系列",
                    ProductDescription:
                        "宁波奥林匹克体育中心",
                    ImagePath: "../assets/XX/nbalpkzx1.jpg",
                    Id: "nbalpkzx",
                },
                {
                    Name: "学校系列",
                    ProductDescription:
                        "上海电影博物馆",
                    ImagePath: "../assets/XX/shdybwg1.jpg",
                    Id: "shdybwg",
                },
                {
                    Name: "学校系列",
                    ProductDescription:
                        "上海音乐厅修缮工程",
                    ImagePath: "../assets/XX/shyyt1.jpg",
                    Id: "shyyt",
                },
                {
                    Name: "学校系列",
                    ProductDescription:
                        "上汽通用五菱前瞻中心",
                    ImagePath: "../assets/XX/sqtywl1.jpg",
                    Id: "sqtywl",
                },
                {
                    Name: "学校系列",
                    ProductDescription:
                        "天津茱莉亚学院",
                    ImagePath: "../assets/XX/tjzlyxy1.jpg",
                    Id: "tjzlyxy",
                },
                {
                    Name: "学校系列",
                    ProductDescription:
                        "中央美院多功能厅改造",
                    ImagePath: "../assets/XX/zymy1.jpg",
                    Id: "zymy",
                },

                {
                    Name: "医院系列",
                    ProductDescription:
                        "南京市公共卫生中心",
                    ImagePath: "../assets/YY/njsggwszx1.jpg",
                    Id: "njsggwszx",
                },
                {
                    Name: "医院系列",
                    ProductDescription:
                        "青大附属医院",
                    ImagePath: "../assets/YY/qdfsyy1.jpg",
                    Id: "qdfsyy",
                },
                {
                    Name: "医院系列",
                    ProductDescription:
                        "深圳市宝安区妇幼保健院",
                    ImagePath: "../assets/YY/szsbaqfybjy1.jpg",
                    Id: "szsbaqfybjy",
                },
                {
                    Name: "医院系列",
                    ProductDescription:
                        "四川省人民医院都江堰分院",
                    ImagePath: "../assets/YY/scsrmyy1.jpg",
                    Id: "scsrmyy",
                },
                {
                    Name: "医院系列",
                    ProductDescription:
                        "长春中医药大学附属第三临床医院",
                    ImagePath: "../assets/YY/cczyydxfsyy1.jpg",
                    Id: "cczyydxfsyy",
                },
                {
                    Name: "医院系列",
                    ProductDescription:
                        "淄博妇幼保健院",
                    ImagePath: "../assets/YY/zbfybjy1.jpg",
                    Id: "zbfybjy",
                },
            ],
            index: undefined,
            pageSize: 10,
        };
    },
    methods: {
    },
    computed: {
        tag() {
            return this.$route.name;
        },

        cards() {
            if (this.tag == "Grand-Theatre") {
                console.log(666);
                return this.items.filter((f) => f.Name === "大剧院系列");
            }
            else if (this.tag == "school") {
                console.log(777);
                return this.items.filter((f) => f.Name === "学校系列");
            }
            else if (this.tag == "hospital") {
                console.log(888);
                return this.items.filter((f) => f.Name === "医院系列");
            }

            else {
                console.log(999);
                return [...this.items];
            }

        },
    },
    watch: {

    },
    mounted() {
    },
};
</script>
<style lang="less"  scoped>


.card-wrap{
    margin: 0 auto;
    width: 90%;
}
.card-content {
    margin: 30px 20px;
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 50%));
    grid-gap: 20px;
    justify-items: center;
    justify-content: center;
}

.prod-card {
    width: 100%;
    height: 400px;
}
</style>
<!-- <style lang="less" scoped>
.card-wrap {
    // max-width: 1440px;
    // min-width: 1260px;
    margin: 0 auto;
    width: 90%;
}

.card-content {
    margin: 30px 20px;
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 50%));
    grid-gap: 20px;
    justify-items: center;
    justify-content: center;
}

.prod-card {
    width: 100%;
    height: 400px;
}
</style> -->
